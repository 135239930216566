#components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
}

.site-layout .site-layout-background {
    background: #fff;
}
.panel_sider{
    background: #fff;
}


.ant-menu-item ant-menu-item-selected:before {
    background: #fff;
}

:global(.ant-menu-item ant-menu-item-selected:before){
    background-color: #61dafb;

}
