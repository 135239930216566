.contentView{
	padding: 0;
	margin: 0;
	width: 100vw;
	overflow-x: hidden;
	height:auto;
	background-color: #999;

	 


}
 

 
.solution_Topviwbox{
	display: flex; 
	background-color: #f6d808;
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: auto;
	flex-direction: column;
	 
 
}