.contactView{
	padding: 0;
	margin: 0;
	width: 100vw;
	overflow-x: hidden;
	height:auto;
	background-color: #f5f5f5;

	 


}


.about_Topviwbox{
	display: flex; 
	background-color: #f6d808;
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: auto;
	flex-direction: column;
 
}

.triangleBox{ 
    align-self: center;
    background-color: #fff;
    width: 100%;
    height: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    vertical-align: top;
    flex-direction: column;
 
  
}
.contact_vissionBox{
	width: 100%;
	height: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f5;
	padding-bottom: 80px;
	flex-direction: column;


}
.aboutTitle{
	font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-size: 25px; 
	font-weight: bold;;
	letter-spacing: 2px;
	text-align: left;
	align-self: flex-start;
    
   }
.contact_Textcontent{
	 width: 100%;
  font-size:14px;
  font-family:  "Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.4px;
  line-height: 28px;
  text-align: left;
  padding-top: 10px;
} 
.about_Textsubline{
   font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-size: 18px; 
	font-weight: bold;;
	letter-spacing: 1.5px;
	text-align: left;
	align-self: flex-start;
}
 
.about_iconview{
	width: 40%;
	justify-content: space-around;
	display: flex;
	position: absolute;
}
.about_iconitems{
  width: 60px;
  height: 60px;	
  min-width: 40px;
  min-height: 40px;
  background-color: #000;
  text-align: center;
  border-radius: 50%;
  margin:0px;
  display: flex;
  justify-content: center;
  align-items: center;
}