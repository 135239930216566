.App {
  text-align: center;

}

.mainContentBox{
  width: 100vw;
  height: auto;
  position: absolute;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
   
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

nav{
  display: flex;
  justify-content:space-between;
  align-items: center;
  min-height: 8vh;
  color:#fff;
  position: fixed;
  width: 100%;


}
.Top-logo{
  height: 54px;
  padding-left: 10vmin;
  min-height: 30px;

 }

.Bottom-logo{
  height: 4vmin;
 
 min-height: 30px;

}

.topbar{
  padding:0;
  position: fixed;

}
.navBar_bg{
  width: 100vw;
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  background-color: #fff;
  align-items: center;
  position: absolute;
  opacity: 0;
}
.navBar_bg_enable{
 
  background-color: #fff;
  opacity: 1;

 
}

.navBar_box{
  width: 100vw;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  vertical-align: center;
  position: absolute;


}
.nav_content{
  width: 90%;
  justify-content: space-between;
  display: flex;
  height: 100%;
  vertical-align: center;
  align-items: center;
  font-family: "微软雅黑","Microsoft YaHei","黑体","宋体",sans-serif;
  font-size: 14px;


}
.textMin_content{
  font-family:  "微软雅黑","Microsoft YaHei","黑体","宋体",sans-serif;
  font-size: 13px;
  font-weight: normal;
  letter-spacing: 2px;

}
.textMin_content_bold{
  font-family:  "微软雅黑","Microsoft YaHei","黑体","宋体",sans-serif;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 2px;

}
.nav_content_mobile{
  width: 100%;
  justify-content: space-around;
  position: absolute;
  display: flex;

 
 
}
.nav_items_mobile{
  width: 70%;

}
.menuIcon{
  width: 40px;
  align-self: right;
  
}
.nav-links{
  width: 60%;
  padding-top: 14px;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  text-decoration: none;


}

.control-bar{
  width: 360px;
  height: auto;
  display: flex;
  padding-top: 14px;
  justify-content: space-around;
  align-items: center;
  list-style: none;

  padding-right: 6vmin;
}
.nav_Space{
  width: 30%;
 
  display: flex;
  height: 2vmin;

}
.buttonWhite{

      transition:0.25s ease-out all;
      width:'200px';
      display: table;
      height:'50px';
}    
.footerComponent{

}
.contentcenColumn{
  flex-direction: column;
  display: flex;
  align-items: left;
}
.flexframeBox_Colum_ex{
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;


}

.flexframeBox_Colum{
  width: 80%;
  height: 80%;
  flex-direction: column;
  display: flex;
  align-items: center;

}
.ATitle{
  font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
  color: #000;
  font-size: 25px; 
  font-weight: bold;;
  letter-spacing: 2px;
  text-align: left;

   }
.ATextcontent{
   width: 100%;
  font-size:14px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.4px;
  line-height: 28px;
  text-align: center;
  padding-top: 10px;

}    
.framTitle{
  font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
  color: #000;
  font-size: 18px;
  align-items: center;
  font-weight: bold;;
  letter-spacing: 2px;
  text-indent: 5px;

 
}
.framContent_center{
  width: 100%;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: justify;
  text-align-all: justify;
  text-justify:distribute-all-lines;

  padding-top: 20px;

  text-align: center;




}


.framContent{
  width: 80%;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #666;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: left;
  padding-top: 20px;
  text-align: justify;
 
}
.ScontentText{
  width: 100%;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #666;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: left;
  padding-top: 40px;
  text-align: justify;
   
}
.pageNavText{
  width: auto;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: left;
  padding-top: 40px;
  text-align: justify;

   
}
.contentText{
  width: auto;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: left;
  text-align: justify;
  

}

.contentText_center{
  width: auto;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.8px;
  line-height: 18px;
  text-align: center;
  text-align: justify;


}

.triangleDown{
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #fcfcfc ;
}
.triangleDown_Yellow{
    width: 0;
    height: 0;
    border: 20px solid transparent;
    border-top-color: #f6d808 ;
}
.triangleDown_White{
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-top-color: #fff ;
}
.triangleDown_White_r{

  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-left: 20px solid #fff;
  border-bottom: 20px solid transparent;
}

.triangleDown_White_l{

  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-right: 20px solid #fff;
  border-bottom: 20px solid transparent;
}

.inputText{
 width: auto;
  font-size:12px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体";
  color: #666;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-align: left;
  text-indent: 5px;

  text-align: justify;
  -webkit-appearance: none;
  border-radius: 0;

}

.selectText{
  width: auto;
  font-size:12px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体";
  color: #666;
  letter-spacing: 1.5px;
  line-height: 20px;
  text-align: left;
  text-indent: 5px;

  text-align: justify;
  background: url("../asset/selecticon.png") no-repeat right center transparent ;
  -webkit-appearance:none;
  background-size: 25px;
  -webkit-appearance:none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -khtml-border-radius: 0;

}

.talentsname{
  font-family:   sans-serif;
  color: #555;
  font-size: 17px;
  font-weight: bold;

}

.talentstitle{
  font-family:   sans-serif;
  color: #999;
  font-size: 12px;

  font-weight: normal;
}



.nodefaultstyle{

}

.pageLineS{

            width: 80px;
            border-bottom: 1px solid black;
}

.navLineS{
  margin-top:6px;
  width: 80px;
  border-bottom: 2px solid black;
}

.contentFrame_alignL{
   
  text-align: left;
  width:100%;
  height: auto;
  display: flex;
  flex-direction: column;

}
.contentFrame_alignR{
  align-items: flex-end;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:row-reverse;
  vertical-align: bottom;

}

.contentFrame_center{
   align-self: center;
   width: 70%;
   
}

.items_placeCenter_W{
  width: 100%;
  height:auto;

  display:flex;
  justify-content: center;
}

.items_placeCenter_V{
  width: 100vw;
  height:auto;
  align-items: center;

  display:flex;
  justify-content: center;
}

.contentFrameBox{
    width: 100%;
    padding-top: 0px;
    align-items: center;
    justify-content: center; 
    display: flex;
    flex-direction: column;


}
.contentFrameColumn{
  width: 100%;
  height: 100%;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  flex-direction: column;


}

.selfcenter_alignLeft{
  align-self: center; 
  align-items: left;
}
.selfcenter_alignRight{
  align-self: center; 
  align-items: center;
  justify-content: flex-end;
  display: flex;
}

.contentFrameRow{
  width: 100%;
  height: auto;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.Stitle{
  font-family: "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
  color: #000;
  font-size: 24px;
  align-items: center;
  letter-spacing: 2px;
  
  float: left;
}
.Subtitle{
  font-family: "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
  color: #000;
  font-size: 18px;
  align-items: center;
  letter-spacing: 2px;

  float: left;
}



.alignBottmR{
  width: 100%;
  height: auto;
 
  vertical-align: bottom;;
  align-items: flex-end;
  display: flex;
  flex-direction:row-reverse;
}
.Topbutton{
  width: 60px;
  height: 60px;
  background-color: #f6d808;
  text-align: center;
  border-radius: 50%;
  position: fixed;
  margin:20px;
  box-shadow:0px 0px 2px #999;
  display: flex;
  justify-content: center;
  align-items: center;
  transform:rotate(180deg);
  right: 10px;
  z-index:30;


}






@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face{
  font-family: PuHuiTi-bold;
  src:url('../asset/fonts/Alibaba-PuHuiTi-Bold.ttf');

}


@font-face {
  font-family: 'icomoon';
  src:  url('../asset/fonts/icomoon.eot?c1cp8p');
  src:  url('../asset/fonts/icomoon.eot?c1cp8p#iefix') format('embedded-opentype'),
    url('../asset/fonts/icomoon.ttf?c1cp8p') format('truetype'),
    url('../asset/fonts/icomoon.woff?c1cp8p') format('woff'),
    url('../asset/fonts/icomoon.svg?c1cp8p#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



.icon-flash:before {
  content: "\e901";
}

.icon-dam:before {
  content: "\e902";
}
.icon-cor:before {
  content: "\e903";
}

.icon-weibo:before {
  content: "\e905";
}
.icon-wechat:before {
  content: "\e906";
}

.icon-topbtn:before {
  content: "\e912";
}
.icon-pen:before {
  content: "\e90b";
}
.icon-msg:before {
  content: "\e90c";
}
.icon-question:before {
  content: "\e90e";
}
.icon-wrong:before {
  content: "\e908";
}
.icon-right:before {
  content: "\e909";
}

.icon-regtypecl:before {
  content: "\e907";
}
.icon-regtypefl:before {
  content: "\e913";
}
