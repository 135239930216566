:global(.ant-picker .antd_style){
    position: absolute;
    border: 0 none;
    opacity: 0;
    background-color: #61dafb;
    box-shadow:none !important;
    outline:none !important;

}


:global(ant-picker antd_style){
    background-color: #61dafb;
    box-shadow:none !important;
    outline:none !important;
}
