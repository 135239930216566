.mainView{
	padding: 0;
	margin: 0;
	width: 100vw;
	overflow-x: hidden;
	height: 4000px;
	background-color: #999;




}
.bannerviwbox{
	display: flex; 
	background-color: #FFC400;
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-image: url("../asset/banner.jpg");
	background-size: 100% 116%;
	background-position: center;
	background-repeat: no-repeat;
 
}
.bannerbg{
	align-self: center;
}
 
.sologan{
	 align-self: center;
	 position: absolute;
 	 width: 56%;
	 text-align: center;
	justify-content: center;



	 
} 
 
.txt01{
	width: 45%;
	min-width: 270px;
	font-size: 1vw;
	font-family: "PuHuiTi-heavy","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-weight: bold;
	 letter-spacing: 4px;
	  
} 

.txt02{
    width: 54%;
    min-width: 260px;
	font-size:16px;
	font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
	color: #000;
    letter-spacing: 2px;
    line-height: 25px;
    padding-top: 10px;
    
 
}
.logotext{
	font-style: italic;
}
.txt03{
	font-size: 60px;
	font-family:"Arial Black",Tahoma,"Helvetica Neue","微软雅黑","Microsoft YaHei";
	color: #fff;
	font-weight: bold;
	  
}

.txt04{
	width: 47%;
	min-width: 330px;
	font-size: 28px;
	font-family: "PuHuiTi-heavy","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-weight: bold;
	 letter-spacing: 3px;
	 text-align: center;
	  
}
.txt05{
   width: 30%;
   min-width: 340px;
  font-size:13px;
  font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
  color: #000;
  letter-spacing: 1.4px;
  line-height: 24px;
  text-align: center;
  padding-top: 20px;
 
}
.txt06{
	width: 25%;
	font-size: 22px;
	font-family: "PuHuiTi-heavy","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-weight: bold;
	letter-spacing: 1px;
	text-align: left;
}

.txt07{
	font-size:16px;
	font-family:  "微软雅黑","Microsoft YaHei", "黑体","宋体",sans-serif;
	color: #000;


}

.columnTitle{
	font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-size: 25px; 
	font-weight: bold;;
	letter-spacing: 4px;
    text-indent: 5px;
     
  
    
}

.servicea{
	background: url("../asset/bgservicea.png")  no-repeat center;
	background-size:50%;
	display: flex;
	justify-content: center;
	align-items: center;

}
.serviceb{
	background: url("../asset/bgserviceb.png")  no-repeat center;
	background-size:50%;
	display: flex;
	justify-content: center;
	align-items: center;

}
.servicec{
	background: url("../asset/bgservicec.png")  no-repeat center;
	background-size:50%;
	display: flex;
	justify-content: center;
	align-items: center;

}
.columnTitleline{
	width: 134px;
	height: 4px;
	background-color: #f6d808;

}
.column02{
	width: 100vw;
	height: auto;
	 
	position: absolute;
	top:100vh;


}

.aboutTitle{
	font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-size: 25px;
	font-weight: bold;;
	letter-spacing: 2px;
	text-align: left;
	align-self: flex-start;

}
.about_Textsubline{
	font-family:  "PuHuiTi-bold","Microsoft YaHei","黑体","宋体",sans-serif;
	color: #000;
	font-size: 18px;
	font-weight: bold;;
	letter-spacing: 1.5px;
	text-align: left;
	align-self: flex-start;
}

.about_Topviwbox{
	display: flex;
	background-color: #f6d808;
	width: 100%;
	overflow: hidden;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: auto;
	flex-direction: column;

}


.scrollingIcon{
	transition: 0.15s ease-out all;
	position: fixed;
	width: 1.8vw;
	min-width: 30px;
	right: 20px;

}

 
.contentFrameColumn{
	width: 100%;

	 flex-direction: column;

}

.contentFrameColumnb2{

	width: 100%;
	height: 100%;
	align-items: center;
	align-self: center;
	display: flex;
	justify-content: center;
	flex-direction: column;


}


.contentFrameColumn_ex{
	width: 100%;
	display: flex;
	align-items: center;
	padding-top: 10px;
	text-align: center;
	flex-direction: column;

}
.serviceBlock{
	 
    padding-top: 30px;
	height: auto;
	vertical-align: top;
	align-self: center;
	background-color: #fff;
 
}

.serviceImage{
	width: 24vw;
    min-width: 380px;  
	float: right;
	padding-left:40px;
	padding-right: 20px;
    padding-bottom: 20px;

}
.serviceInfo{
	width: 24vw;
	min-width: 380px;  
	height: 17vw;
    min-height: 260px;
	float: right;
	padding-right: 40px;
	padding-left: 20px;


} 




.frameAligncenter{
    
    align-self: center;

    width: auto;
    height: auto;
   

  
}

.frameAligncenterb2{

	align-self: center;
	display:inline-block !important;
	display:inline;
	width: auto;
	height: auto;



}



.frameAlignrow{
  
    align-self: center;
    display:flex;
    justify-content: space-between;
    width: auto;
    height: auto;
   

  
}

@font-face{
	font-family: PuHuiTi-heavy;
	src:url('../asset/fonts/Alibaba-PuHuiTi-Heavy.ttf');

}

@font-face{
	font-family: PuHuiTi-bold;
	src:url('../asset/fonts/Alibaba-PuHuiTi-Bold.ttf');

}

 