.contentView{
    padding: 0;
    margin: 0;
    width: 100vw;
    overflow-x: hidden;
    height:auto;
    background-color: #999;




}



.solution_Topviwbox{
    display: flex;
    background-color: #f6d808;
    width: 100%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    flex-direction: column;


}
.whattop{
    font-family:  "PuHuiTi-medium","Microsoft YaHei","黑体","宋体",sans-serif;
    color: #000;
    font-size: 24px;
    font-weight: bold;;
    text-align: center;
    line-height: 40px;
}

.whatcontent{
    font-family:  "PuHuiTi-medium","Microsoft YaHei","黑体","宋体",sans-serif;
    color: #000;
    font-size: 18px;
    text-align: left;
    line-height: 30px;
}

@font-face{
    font-family: PuHuiTi-medium;
    src:url('../asset/fonts/Alibaba-PuHuiTi-Medium.ttf');

}

@font-face{
    font-family: PuHuiTi-regular;
    src:url('../asset/fonts/Alibaba-PuHuiTi-Regular.ttf');

}