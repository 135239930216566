body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-top: 0;
  overflow-x: hidden;
  height: auto;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:global(.ant-picker .antd_style){
  position: absolute;
  border: 0 none;
  opacity: 0;
  background-color: #61dafb;
  box-shadow:none !important;
  outline:none !important;

}


:global(ant-picker antd_style){
  background-color: #61dafb;
  box-shadow:none !important;
  outline:none !important;
}
